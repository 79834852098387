import React, { Component } from "react";
import { connect } from "react-redux";
import userActions from "../../redux/actions/user-actions";
import footPrintsActions from "../../redux/actions/footprints-actions";
import FootPrintsListView from "../../components/common/footprints-list-view";
import TotalFootPrintsView from "../../components/common/total-footprints-view";
import TeacherClassesCheckboxListView from "../../components/teacher/teacher-classes-checkbox-list-view";
import StudentsListView from "../../components/common/students-list-view";
import CreateFootprintView from "../../components/common/create-footprint-view";
import jQueryHelper from "../../helpers/jquery-helper";
import Grow from "@material-ui/core/Grow";
import mixConstants from "../../constants/mix-constants";
import footPrintsApi from "../../apis/footprints-api";
import CreateFootprintModalView from "../../components/common/create-footprint-modal-view";

class TeacherOverviewPage extends Component {
  constructor() {
    super();

    this.footPrintsCurrentPage = 1;

    this.state = {
      footPrintsPageSize: mixConstants.constantValues.FOOT_PRINTS_PAGE_SIZE,

      fetchingCurrentUserContributors: null,
      currentUserContributorsFetched: null,
      currentUserContributors: null,
      failedToFetchCurrentUserContributors: null,

      fetchingCurrentUserFootPrints: null,
      currentUserFootPrintsFetched: null,
      currentUserFootPrintsResponse: null,
      currentUserFootPrints: [],
      failedToFetchCurrentUserFootPrints: null,

      totalFootprints: 0,
      allClasses: [],
      allClassesStudents: [],

      selectedClasses: [],
      selectedClassesStudents: [],
      selectedClassesGroupsIds: "",

      isFilteringClasses: false,
      hasNextPage: true,

      isDeletingFootPrint: false,
      deletingFootPrintId: 0,

      footPrintToUpdate: null,

      update: false,
    };

    this.loadMoreFootprints = this.loadMoreFootprints.bind(this);
    window.addEventListener("scroll", this.loadMoreFootprints);
    window.addEventListener("resize", this.loadMoreFootprints);
  }

  // component lifecycle methods

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      fetchingCurrentUserContributors,
      currentUserContributorsFetched,
      currentUserContributors,
      failedToFetchCurrentUserContributors,
    } = nextProps;

    let totalFootprints = prevState.totalFootprints;
    let allClasses = prevState.allClasses;
    let allClassesStudents = prevState.allClassesStudents;

    if (currentUserContributors) {
      if (currentUserContributors.total_footprints) {
        totalFootprints = currentUserContributors.total_footprints;
      }
      if (
        currentUserContributors.school_classes &&
        currentUserContributors.school_classes.length > 0
      ) {
        allClasses = currentUserContributors.school_classes;
        allClasses =
          TeacherOverviewPage.mappedClassesOnCustomObject(allClasses);

        if (allClasses.length > 0) {
          let students = allClasses.map((classObj) => {
            return classObj.students;
          });
          students = [].concat.apply([], students);
          allClassesStudents = students;
        }
      }
    }

    return {
      fetchingCurrentUserContributors: fetchingCurrentUserContributors,
      currentUserContributorsFetched: currentUserContributorsFetched,
      currentUserContributors: currentUserContributors,
      failedToFetchCurrentUserContributors:
        failedToFetchCurrentUserContributors,

      totalFootprints: totalFootprints,
      allClasses: allClasses,
      allClassesStudents: allClassesStudents,
    };
  }

  componentDidMount() {
    this.loadJQuery();
    this.fetchCurrentUserContributors();

    this.fetchCurrentUserFootPrints(
      this.footPrintsCurrentPage,
      this.state.footPrintsPageSize,
      ""
    );
  }

  componentWillUnmount() {
    this.footPrintsCurrentPage = 1;
  }

  // helper methods

  loadJQuery() {
    jQueryHelper.loadSideBarArrowButtonHideShowJQuery();
    jQueryHelper.loadDashboardJQuery();
  }

  fetchCurrentUserContributors() {
    if (this.props.fetchCurrentUserContributors) {
      this.props.fetchCurrentUserContributors();
    }
  }

  loadMoreFootprints() {
   const scrollBuffer = 50;
   if (
     window.innerHeight + document.documentElement.scrollTop >=
     document.scrollingElement.scrollHeight - scrollBuffer
   ) {
     if (!this.state.fetchingCurrentUserFootPrints && this.state.hasNextPage) {
       const page = this.footPrintsCurrentPage + 1;
       this.footPrintsCurrentPage = page;
       const pageSize = this.state.footPrintsPageSize;
       const classesOrGroupsId = this.state.selectedClassesGroupsIds;
       this.fetchCurrentUserFootPrints(page, pageSize, classesOrGroupsId);
     }
   }
  }

  fetchCurrentUserFootPrints(page, pageSize, classesOrGroupsId) {
    this.setState({
      fetchingCurrentUserFootPrints: true,
    });

    footPrintsApi
      .fetchCurrentUserFootPrints(page, pageSize, classesOrGroupsId)
      .then(
        (response) => {
          let footPrints = this.state.currentUserFootPrints;
          const hasNextPage = response.next ? true : false;

          if (
            JSON.stringify(this.state.currentUserFootPrints) !==
            JSON.stringify(response.results)
          ) {
            footPrints = [].concat.apply(
              this.state.currentUserFootPrints,
              response.results
            );
          }

          this.setState({
            fetchingCurrentUserFootPrints: false,
            currentUserFootPrintsFetched: true,
            currentUserFootPrintsResponse: response,
            currentUserFootPrints: footPrints,
            failedToFetchCurrentUserFootPrints: false,
            hasNextPage: hasNextPage,
          });
        },
        (error) => {
          let hasNextPage = this.state.hasNextPage;
          if (
            error.detail &&
            error.detail.toLowerCase().includes("invalid page")
          ) {
            hasNextPage = false;
          }
          this.setState({
            fetchingCurrentUserFootPrints: false,
            currentUserFootPrintsFetched: false,
            failedToFetchCurrentUserFootPrints: true,
            hasNextPage: hasNextPage,
          });
        }
      );
  }

  static mappedClassesOnCustomObject(classes) {
    const mappedClassesObjects = classes.map((classObj) => {
      const class_id = classObj.id;
      const class_name = classObj.class_name;
      const students = classObj.school_students;

      return {
        id: class_id,
        name: class_name,
        isChecked: false,
        students: students,
      };
    });
    return mappedClassesObjects;
  }

  newFootPrintCreated(footPrint) {
    this.resetPagination();
    this.fetchCurrentUserContributors();
    this.fetchCurrentUserFootPrints(1, this.state.footPrintsPageSize, "");
  }

  footPrintUpdated(footPrint) {
    const footPrints = [...this.state.currentUserFootPrints];

    const index = footPrints.findIndex((fp) => {
      return fp.id === footPrint.id;
    });

    if (index >= 0) {
      footPrints[index] = {};
    }

    this.setState({
      footPrintToUpdate: null,
      currentUserFootPrints: footPrints,
    });

    setTimeout(() => {
      footPrints[index] = footPrint;
      this.setState({
        footPrintToUpdate: null,
        currentUserFootPrints: footPrints,
      });
    }, 100);
  }

  userDidCancelToUpdateFootPrint() {
    this.setState({
      footPrintToUpdate: null,
    });
  }

  // buttons action methods

  applyFilterButtonClicked(selectedClasses) {
    const ids = selectedClasses
      .map((classObj) => {
        return classObj.id;
      })
      .join(",");

    this.resetPagination();

    this.fetchCurrentUserFootPrints(1, this.state.footPrintsPageSize, ids);
    this.renderSelectedClassesStudents(
      selectedClasses.length === 0 ? this.state.allClasses : selectedClasses
    );

    this.setState({
      selectedClassesGroupsIds: ids,
      selectedClasses:
        selectedClasses.length === 0 ? this.state.allClasses : selectedClasses,
    });
  }

  renderSelectedClassesStudents(selectedClasses) {
    let students = selectedClasses.map((classObj) => {
      return classObj.students;
    });
    students = [].concat.apply([], students);

    const isFilteringClasses = selectedClasses.length > 0;

    this.setState({
      selectedClassesStudents: students,
      isFilteringClasses: isFilteringClasses,
    });
  }

  resetPagination() {
    this.footPrintsCurrentPage = 1;

    this.setState({
      hasNextPage: true,
      currentUserFootPrints: [],
    });
  }

  editFootPrintClicked(footPrint) {
    if (footPrint.id) {
      this.setState({
        footPrintToUpdate: footPrint,
      });
    }
  }

  deleteFootPrintClicked(footPrint) {
    if (footPrint.id) {
      this.setState({
        isDeletingFootPrint: true,
        deletingFootPrintId: footPrint.id,
      });
      footPrintsApi.deleteFootPrint(footPrint.id).then(
        (status) => {
          const { currentUserFootPrints } = this.state;
          const filteredFootPrints = currentUserFootPrints.filter(
            (footPrintObj) => {
              return footPrintObj.id !== footPrint.id;
            }
          );
          this.fetchCurrentUserContributors();
          this.setState({
            currentUserFootPrints: filteredFootPrints,
            isDeletingFootPrint: false,
            deletingFootPrintId: 0,
          });
        },
        (error) => {
          this.setState({
            isDeletingFootPrint: false,
            deletingFootPrintId: 0,
          });
        }
      );
    }
  }
  filterUniqueStudents = () => {
    const { allClassesStudents } = this.state;
    const uniqueStudents = allClassesStudents.filter((obj, index) => {
      return index === allClassesStudents.findIndex((o) => obj.id === o.id);
    });
    return uniqueStudents;
  };

  filterSelectedClassStudents = () => {
    const { selectedClassesStudents } = this.state;
    const uniqueStudentsInClass = selectedClassesStudents.filter(
      (obj, index) => {
        return (
          index === selectedClassesStudents.findIndex((o) => obj.id === o.id)
        );
      }
    );
    return uniqueStudentsInClass;
  };

  // render

  render() {
    let {
      fetchingCurrentUserContributors,
      currentUserContributorsFetched,
      currentUserContributors,
      failedToFetchCurrentUserContributors,

      fetchingCurrentUserFootPrints,
      currentUserFootPrintsFetched,
      currentUserFootPrints,
      failedToFetchCurrentUserFootPrints,

      totalFootprints,
      allClasses,

      isFilteringClasses,

      isDeletingFootPrint,
      deletingFootPrintId,

      footPrintToUpdate,
    } = this.state;

    const filteredStudents = this.filterUniqueStudents();
    const filteredStudentsForEachClass = this.filterSelectedClassStudents();

    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-8 post-container">
              <CreateFootprintView
                classesGroups={allClasses}
                onFootPrintCreated={(footPrint) =>
                  this.newFootPrintCreated(footPrint)
                }
              />
              <FootPrintsListView
                fetchingFootPrints={
                  currentUserFootPrints.length >=
                  mixConstants.constantValues.FOOT_PRINTS_PAGE_SIZE
                    ? false
                    : fetchingCurrentUserFootPrints
                }
                isLoadingMore={
                  currentUserFootPrints.length >=
                    mixConstants.constantValues.FOOT_PRINTS_PAGE_SIZE &&
                  fetchingCurrentUserFootPrints
                }
                footPrintsFetched={currentUserFootPrintsFetched}
                footPrints={currentUserFootPrints}
                failedToFetchFootPrints={failedToFetchCurrentUserFootPrints}
                currentPage={this.footPrintsCurrentPage}
                isDeletingFootPrint={isDeletingFootPrint}
                deletingFootPrintId={deletingFootPrintId}
                onEdit={(footPrint) => this.editFootPrintClicked(footPrint)}
                onDelete={(footPrint) => this.deleteFootPrintClicked(footPrint)}
              />

              {footPrintToUpdate ? (
                <CreateFootprintModalView
                  classesGroups={allClasses}
                  onFootPrintUpdated={(footPrint) =>
                    this.footPrintUpdated(footPrint)
                  }
                  footPrintToUpdate={footPrintToUpdate}
                  onClose={() => this.userDidCancelToUpdateFootPrint()}
                />
              ) : (
                ""
              )}
            </div>

            <Grow
              in={true}
              style={{ transformOrigin: "0 0 0" }}
              timeout={mixConstants.constantValues.TRANSITION_DURATION}
            >
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4 right-sidebar order-xs-first">
                <div className="sidebar-content">
                  <TotalFootPrintsView totalFootprints={totalFootprints} />

                  <TeacherClassesCheckboxListView
                    fetchingContributors={fetchingCurrentUserContributors}
                    contributorsFetched={currentUserContributorsFetched}
                    contributors={currentUserContributors}
                    failedToFetchContributors={
                      failedToFetchCurrentUserContributors
                    }
                    allClasses={allClasses}
                    onApplyFilter={(selectedClasses) =>
                      this.applyFilterButtonClicked(selectedClasses)
                    }
                  />

                  <StudentsListView
                    fetchingContributors={fetchingCurrentUserContributors}
                    contributorsFetched={currentUserContributorsFetched}
                    contributors={currentUserContributors}
                    failedToFetchContributors={
                      failedToFetchCurrentUserContributors
                    }
                    students={
                      isFilteringClasses
                        ? filteredStudentsForEachClass
                        : filteredStudents
                    }
                  />
                </div>
              </div>
            </Grow>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    fetchingCurrentUserContributors,
    currentUserContributorsFetched,
    currentUserContributors,
    failedToFetchCurrentUserContributors,
  } = state.fetchCurrentUserContributors;

  const {
    fetchingCurrentUserFootPrints,
    currentUserFootPrintsFetched,
    currentUserFootPrintsResponse,
    failedToFetchCurrentUserFootPrints,
  } = state.fetchCurrentUserFootPrints;

  return {
    fetchingCurrentUserContributors,
    currentUserContributorsFetched,
    currentUserContributors,
    failedToFetchCurrentUserContributors,

    fetchingCurrentUserFootPrints,
    currentUserFootPrintsFetched,
    currentUserFootPrintsResponse,
    failedToFetchCurrentUserFootPrints,
  };
}

const actionCreators = {
  fetchCurrentUserContributors: userActions.fetchCurrentUserContributors,
  fetchCurrentUserFootPrints: footPrintsActions.fetchCurrentUserFootPrints,
  clearFetchCurrentUserFootPrints:
    footPrintsActions.clearFetchCurrentUserFootPrints,
};

export default connect(mapStateToProps, actionCreators)(TeacherOverviewPage);
